import { BrowserRouter as Router } from "react-router-dom";
import React, { Component } from "react";
import RouteData from "./RouteData";

import "./App.css";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "../node_modules/bootstrap/dist/js/bootstrap";
import "../node_modules/font-awesome/css/font-awesome.css";
import "../node_modules/jquery/dist/jquery.min";
import "../node_modules/popper.js/dist/popper";

class App extends Component {
  render() {
    return (
      <Router>
        <RouteData />
      </Router>
    );
  }
}

export default App;
