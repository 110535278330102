import { Route, Switch } from "react-router-dom";
import React, { Component } from "react";
import Clinic from "./components/auth/Clinic";
import "./App.css";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "bootstrap";
import "../node_modules/font-awesome/css/font-awesome.css";
import "jquery/dist/jquery.min";

class RouteData extends Component {
  componentDidMount() {
    // if (
    //   queryString.parse(window.location.search).reference &&
    //   queryString.parse(window.location.search).reference.includes("Bearer")
    // ) {
    //   localStorage.setItem(
    //     "jwtToken",
    //     queryString.parse(window.location.search).reference
    //   );
    //   window.location.href = "/dashboard";
    // }
  }

  render() {
    return (
      <div className="App">
        <Switch>
          <Route path="/" component={Clinic} />
        </Switch>
      </div>
    );
  }
}

export default RouteData;
