import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import $ from "jquery";
import "@fontsource/roboto"; // Defaults to weight 400
import "@fontsource/roboto/400.css"; // Specify weight
import "@fontsource/roboto/400-italic.css"; // Specify weight and style
window.jQuery = $;
window.$ = $;
global.jQuery = $;

ReactDOM.render(<App />, document.getElementById("root"));

$("#menu-toggle").click(function () {
  $("#wrapper").toggleClass("toggled");
});

$('.modal[data-reset="true"]').on("shown.bs.modal", () =>
  $("input[name != 'timestamp']").val("")
);

$(".modal").on("shown.bs.modal", () =>
  $('input[data-reset-input="true"]').val("")
);
