import React, { Component } from "react";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import rxl from "./../../utils/rxl.png";
import "react-confirm-alert/src/react-confirm-alert.css";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      rx_group: "",
      records: [],
      user: undefined,
      filterData: {
        page_number: 1,
        page_size: 20,
        sort_order: { column: "date", order: "desc" },
      },
    };
  }

  componentDidMount() {
    this.getData();
  }
  getData() {
    let filterData = this.state.filterData;
    filterData.search = {
      xpedicareRxcustom_page_name: window.location.pathname.split("/")[1],
    };
    if (window.location.pathname.split("/")[1] === "") {
      confirmAlert({
        title: "Invalid URL",
        buttons: [],
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    } else {
      axios.post("/api/users", filterData).then((res) => {
        var record = [];
        if (res.data.records.length !== 0) {
          for (let i = 0; i < res.data.records.length; i++) {
            let each = res.data.records[i];

            if (each !== "" || each !== undefined) {
              for (let j = 0; j < each.xpedicareRxcustomArray.length; j++) {
                let eachone = each.xpedicareRxcustomArray[j];
                record.push(eachone);
              }
            } else {
              alert("you need to give specific address");
            }
            this.setState({
              records: record,
              user: each,
            });
          }
        } else {
          return confirmAlert({
            title: "Invalid URL",
            buttons: [],
            closeOnEscape: false,
            closeOnClickOutside: false,
          });
        }
      });
    }
  }

  render() {
    return (
      <div className="container">
        <div className="row">
          {this.state.records.length ? (
            <>
              {this.state.user.logo ? (
                <div class="col-sm-12 mt-2">
                  <img
                    src={this.state.user.logo}
                    width={
                      this.state.user.xpedicareRxcustom_page_logo !==
                        undefined &&
                      this.state.user.xpedicareRxcustom_page_logo !== ""
                        ? this.state.user.xpedicareRxcustom_page_logo
                        : 150
                    }
                  />
                </div>
              ) : (
                ""
              )}
              <div class="col-sm-12 mt-3">
                <h3
                  style={{
                    float: "left",
                    color: "#0170bb",
                    marginBottom: 0,
                    fontWeight: "bold",
                  }}
                >
                  What can we help you with today?
                </h3>
                <h4 style={{ float: "right", fontSize: "20px" }}>
                  <a href={this.state.user.website} target="_blank">
                    {this.state.user.name}
                  </a>
                </h4>
              </div>
              <div class="col-sm-12 mt-2">
                <h5
                  style={{
                    fontSize: "20px",
                    fontStyle: "italic",
                    color: "#4a4d4f",
                  }}
                >
                  Click on the treatment below and start your online
                  consultation. Once completed our pharmacy will receive the
                  prescription.
                </h5>
              </div>
            </>
          ) : (
            ""
          )}
          <br></br>
          <br></br>
          {(this.state.records || []).map((each, i) => {
            return (
              <div class="col-sm-3 cardBox">
                <div
                  class="card"
                  style={{
                    background: "#fff",
                  }}
                >
                  <div class="card-body">
                    {each.image && each.image.value ? (
                      <img src={each.image.value} width={60} style={{}} />
                    ) : (
                      <img src={rxl} width={60} style={{}} />
                    )}
                    <h5 className="condition red float-right">
                      {each.condition || ""}
                    </h5>
                    <br></br>
                    <br></br>
                    <h5 class="card-title">{each.name}</h5>

                    {each.description ? (
                      <h5 className="description">{each.description}</h5>
                    ) : (
                      ""
                    )}

                    {each.price ? (
                      <h5 className="condition float-left">{each.price}</h5>
                    ) : (
                      ""
                    )}

                    <button
                      type="button"
                      className="btn btn-primary float-right btn-sm"
                    >
                      <a
                        href={each.link}
                        target="blank"
                        className="card-link float-right"
                        style={{
                          color: "#fff",
                          textDecoration: "none",
                        }}
                      >
                        Start Now&nbsp;
                        <i className="fa fa-arrow-right"></i>
                      </a>
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
export default Login;
